import React, { useEffect } from "react";
import thank_you_image from "../../../../../assets/images/thank_you.svg";

const ThankYouPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 text-center">
        <div className="mb-6">
          <img
            src={thank_you_image}
            alt="Thank You"
            width={300}
            height={200}
          />
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-2">
          Thanks for contacting us!
        </h1>
        <p className="text-[#626262] font-normal text-sm mb-6">
          Our team is reviewing your inquiry and will respond shortly.
        </p>
        <a
          href="/"
          className="bg-gray-800 text-white px-6 py-2 no-underline shadow-md hover:bg-gray-700 rounded-none"
        >
          Back to Website
        </a>
      </div>
    </>
  );
};

// Footer Component for Example (Replace with your actual Footer component)
interface FooterProps {
  handleWhatsAppClick: () => void;
  handleCallClick: () => void;
}

const Footer: React.FC<FooterProps> = ({
  handleWhatsAppClick,
  handleCallClick,
}) => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <button
        onClick={handleWhatsAppClick}
        className="bg-green-500 px-4 py-2 rounded mr-2"
      >
        WhatsApp Us
      </button>
      <button
        onClick={handleCallClick}
        className="bg-blue-500 px-4 py-2 rounded"
      >
        Call Us
      </button>
    </footer>
  );
};

export default ThankYouPage;
