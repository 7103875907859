import { Button } from "../../../ui/button";
import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import proofs from "../../../../../assets/images/recent_work/stats_new.svg";
import { get, post } from "../../../../../utils/api";
import Snackbar from "../../../ui/snackbar";
import axios from "axios";

export default function ServiceHeroSection(props: any) {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="service-hero-section flex flex-col md:flex-row justify-between items-center md:py-20 lg:!px-32 md:!px-24 px-4 py-8 bg-gradient-to-r from-gray-50 to-gray-100">
      {/* Left Section - Text */}
      <div className="md:w-[40%] mb-8 md:mb-0 text-center md:text-left">
        <h2 className="font-sans font-extrabold text-3xl md:text-5xl md:text-start leading-snug mb-4 md:mb-6 text-gray-900">
          Crafting Websites as Unique as Your Business
        </h2>
        <p className="font-sans text-base md:text-xl md:text-start leading-relaxed text-gray-700">
          Tailored Solutions to Reflect Your Unique Brand.
        </p>
        <div className="flex justify-between p-6">
          <div className="w-[350px] text-center flex items-center justify-center">
            <img src={proofs} alt="years" className="w-full" />
            {/* <br></br>
            <p className="text-center text-black font-lato flex items-center justify-center">
              Years
              <br></br>
              Experience
            </p> */}
          </div>
          {/* <div className="w-1/3   text-center flex items-center justify-center">Div 2</div>
            <div className="w-1/3  text-center flex items-center justify-center">Div 3</div> */}
        </div>
      </div>

      {/* Right Section - Form */}
      <Card className="md:w-[320px] lg:w-[400px] rounded-2xl shadow-lg bg-white w-full max-w-md">
        <CardHeader className="bg-gray-100 rounded-t-2xl py-4 md:py-6 px-6 md:px-8">
          <CardTitle className="text-lg md:text-xl font-semibold text-gray-800">
            Request Free Consultation
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 md:p-8">
          {props.error && <p className="text-red-500 mb-4">{props.error}</p>}
          <form onSubmit={props.handleSubmit}>
            <div className="space-y-4 md:space-y-6">
              {/* Name Input */}
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Your Name
                </label>
                <input
                  id="name"
                  placeholder="Your Name"
                  value={props.name}
                  onChange={(e) => props.setName(e.target.value)}
                  className="w-full text-base h-10 md:h-12 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out"
                />
              </div>

              {/* Phone Input */}
              <div className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Phone Number
                </label>
                {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "15px",
                      border: "1px solid #ccc",
                      borderRadius: "8px", // Rounder corners
                      overflow: "hidden",
                    }}
                  > */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    border: isFocused
                      ? "1px solid #3b82f6"
                      : "1px solid #d1d5db", // Blue border on focus
                    borderRadius: "12px", // Rounded corners (like rounded-lg)
                    boxShadow: isFocused
                      ? "0 0 0 4px rgba(59, 130, 246, 0.3)" // Blue ring effect on focus
                      : "0 1px 2px rgba(0, 0, 0, 0.05)", // Subtle shadow (shadow-sm)
                    transition:
                      "border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out", // Smooth transition with duration-300 and ease-in-out
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 4px",
                      borderRight: "1px solid #ccc", // Border between dropdown and input
                      position: "relative", // Make dropdown's position relative for arrow placement
                      width: "120px", // Small width to show only the code
                    }}
                  >
                    <select
                      value={props.countryCode}
                      onChange={props.handleCountryChange}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        padding: "8px 12px",
                        width: "100%", // Full width for the dropdown
                        appearance: "none", // Hides the default dropdown arrow
                        cursor: "pointer",
                        textAlign: "center", // Center-align the country code
                      }}
                    >
                      {props.countryCodeData.map((country: any) => (
                        <option
                          key={country.country_code}
                          value={country.country_code}
                        >
                          {/* When the dropdown is expanded, show both short_name and country code */}
                          {props.countryCode === country.country_code
                            ? country.country_code // Display only the code after selection
                            : `${country.short_name} ${country.country_code}`}{" "}
                          {/* Show both when selecting */}
                        </option>
                      ))}
                    </select>

                    {/* Custom dropdown arrow inside the select box */}
                    {/* <span
                        style={{
                          position: "absolute",
                          right: "2px", // Position the arrow at the right of the select box
                          pointerEvents: "none", // Arrow should not block the dropdown
                          fontSize: "14px",
                        }}
                      >
                        ▼
                      </span> */}
                  </div>

                  {/* Phone Input Field */}
                  <input
                    type="text"
                    placeholder="Phone"
                    value={props.phone}
                    onChange={props.handlePhoneChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    style={{
                      padding: "12px",
                      fontSize: "16px",
                      border: "none",
                      width: "100%", // Ensure the input takes up the remaining space
                      backgroundColor: "#fff", // White background to match the dropdown
                      borderRadius: "0 8px 8px 0", // Rounded corners only on the right side
                    }}
                  />
                </div>
                    {/* Message Input (Text Area) */}
            <div className="flex flex-col mt-4">
              <label htmlFor="message" className="text-sm font-medium mb-1">
                Tell Us More
              </label>
              <textarea
                id="message"
                placeholder="Brief about your project"
                value={props.message}
                onChange={(e) => props.setMessage(e.target.value)}
                className="w-full text-base h-18 px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out resize-none"
              />
            </div>
                {/* <div className="phone-input-wrapper">
                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={(phone: any) => setPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        border: "1px solid #d1d5db",
                        padding: "10px",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      }}
                      buttonStyle={{
                        border: "1px solid #d1d5db",
                        borderRadius: "8px 0 0 8px",
                      }}
                      containerStyle={{
                        width: "100%",
                      }}
                      enableSearch={true}
                      preferredCountries={["in", "us", "gb", "au"]}
                    />
                  </div> */}
              </div>

              {/* Privacy Note */}
              <p className="text-xs font-light text-gray-500">
                Your information is safe with us. We respect your privacy.
              </p>

              {/* Submit Button */}
              <Button
                type="submit"
                className="py-3 md:py-4 px-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-base md:text-lg font-semibold rounded-lg hover:from-purple-600 hover:to-blue-600 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600 !w-full max-w-lg"
              >
                Request Free Consultation
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      {/* Snackbar for Success Message */}
    </div>
  );
}
