import React, { useState } from "react";
import { Button } from "../../../ui/button";
import "react-phone-number-input/style.css";

interface EnquiryFormProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (event: React.FormEvent) => void;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  countryCode: string;
  handleCountryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  countryCodeData: Array<{ short_name: string; country_code: string }>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  error?: string;
}

const EnquiryForm: React.FC<EnquiryFormProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  name,
  setName,
  phone,
  handlePhoneChange,
  countryCode,
  handleCountryChange,
  countryCodeData,
  message,
  setMessage,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-2xl w-full max-w-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-4 px-6 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Enquire Now</h2>
          <button onClick={onClose} className="text-gray-300 hover:text-white">
            &times;
          </button>
        </div>

        {/* Modal Body */}
        <div className="px-6 py-8">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            {/* Name Input */}
            <div className="flex flex-col">
              <label htmlFor="name" className="text-sm font-medium mb-1">
                Your Name
              </label>
              <input
                id="name"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full text-base h-10 md:h-12 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out"
              />
            </div>

            {/* Country Code Selector */}
            <div className="flex flex-col mt-4">
              <label htmlFor="countryCode" className="text-sm font-medium mb-1">
                Country Code
              </label>
              <select
                id="countryCode"
                value={countryCode}
                onChange={handleCountryChange}
                className="w-full text-base h-10 md:h-12 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out"
              >
                {countryCodeData.map((country) => (
                  <option key={country.short_name} value={country.country_code}>
                    {country.short_name} ({country.country_code})
                  </option>
                ))}
              </select>
            </div>

            {/* Phone Input */}
            <div className="flex flex-col mt-4">
              <label htmlFor="phone" className="text-sm font-medium mb-1">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                placeholder="Your Phone Number"
                value={phone}
                onChange={handlePhoneChange}
                className="w-full text-base h-10 md:h-12 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out"
              />
            </div>

            {/* Message Input (Text Area) */}
            <div className="flex flex-col mt-4">
              <label htmlFor="message" className="text-sm font-medium mb-1">
                Tell Us More
              </label>
              <textarea
                id="message"
                placeholder="Brief about your project"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full text-base h-28 px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out resize-none"
              />
            </div>

            {/* Privacy Notice */}
            <p className="text-xs font-light text-gray-500 mt-2">
              Your information is safe with us. We respect your privacy.
            </p>

            {/* Submit Button */}
            <Button
              type="submit"
              className="py-3 md:py-4 px-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-base md:text-lg font-semibold rounded-lg hover:from-purple-600 hover:to-blue-600 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600 !w-full mt-6"
            >
              Enquire Now
            </Button>
               {/* Close Button */}
               <Button
              type="button"
              onClick={onClose}
              className="py-3 md:py-4 px-6 bg-gray-200 text-gray-700 text-base md:text-lg font-semibold rounded-lg hover:bg-gray-300 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400 !w-full mt-4"
            >
              Close
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
