import { FC, useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import Footer from "../modules/common/footer";
import Header from "../modules/common/header";
import Home from "../modules/components/home";
import ApplicationForm from "../modules/components/squad/application-form";
import Squad from "../modules/components/squad/squad";
import Products from "../modules/components/products/products";
import ContactUs from "../modules/components/contact-us";
import Navbar from "../modules/common/Navbar/Navbar";
import About from "../modules/components/about-us";
import TermsOfService from "../modules/components/terms/terms";
import PrivacyStatement from "../modules/components/privacy/privacy";
import RefundPolicy from "../modules/components/refund/refund";
import OurServices from "../modules/components/our-services";
import WebsiteDesignersEnquiry from "../modules/components/website-designers-enquiry";
// import WebsiteServiceLanding from "../modules/components/website-landing";
import LPWebsiteDesignSevicesV1 from "../modules/components/landing-pages/website-services/v1";
import LPWebsiteDesignSevicesV2 from "../modules/components/landing-pages/website-services/v2";
import LPWebsiteDesignSevicesV3 from "../modules/components/landing-pages/website-services/v3";
import DynamicEnquiryForm from "../modules/components/landing-pages/dynamic-enquiry-form";
import ThankYouPage from "../modules/components/landing-pages/website-services/v3/thank-you";

// import WebsiteServiceLanding from "../modules/components/landing-service";

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/website-design-enquiry"
          element={<WebsiteDesignersEnquiry />}
        />

        <Route
          element={
            <>
              <Navbar />
              {/* <Header /> */}
              <App />
              <Footer />
            </>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-products" element={<Products />} />
          <Route path="/squad" element={<Squad />} />
          <Route path="/application-form" element={<ApplicationForm />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/terms&conditions" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyStatement />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/thank-you" element={<ThankYouPage />} />

          {/* <Route path="/our-services" element={<OurServices />} /> */}

          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/home" index element={<Navigate to="/" />} />
        </Route>
        {/* <Route path="/website-landing" element={<LPWebsiteDesignSevicesV1 />} /> */}
        {/* <Route path="/lp/website-services/v1" element={<LPWebsiteDesignSevicesV1 />} /> */}
        {/* <Route path="/lp/website-services/v2" element={<LPWebsiteDesignSevicesV2 />} /> */}
        <Route path="/lp/website-services/v3" element={<LPWebsiteDesignSevicesV3 />} />
        
        <Route path="/lp/website-services" element={<LPWebsiteDesignSevicesV3 />} />
        <Route path="/lp/enquiry" element={<DynamicEnquiryForm />} />
        
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
