import { useState } from "react";
import EnquiryForm from "./enquiry-form";
import { Card, CardContent } from "../../../ui/card"; // Adjust paths to the actual location of Card and other UI components

// Define types for the features and plans
interface Feature {
  title: string;
  description: string;
}

interface Plan {
  title: string;
  price: string;
  features: Feature[];
}

function OurPlans(props:any) {
  // // Define the state types
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // // Event handlers
  // const handleOpenModal = () => setIsModalOpen(true);
  // const handleCloseModal = () => setIsModalOpen(false);

  // // Pricing plans data with feature title and description

  let DelnieCloudAdminDesc =
    "Delnie Cloud Admin is designed to efficiently manage master data, enabling easy analysis through Mongo charts and seamless API creation for streamlined operations. It provides centralized data management for all employees, complete with role-level restrictions to enhance security and access control.";
  const plans: Plan[] = [
    {
      title: "Basic Plan",
      price: "₹30,000",
      features: [
        {
          title: "Pages",
          description:
            "Up to 10 custom-designed, fully responsive pages that adapt seamlessly to desktop and mobile devices.",
        },
        {
          title: "Design",
          description:
            "Responsive design tailored for optimal user experience on all devices.",
        },
        {
          title: "Custom Designs",
          description:
            "Every design is uniquely crafted for your business, without using pre-made templates.",
        },
        {
          title: "SEO Optimization",
          description:
            "Basic SEO setup to ensure search engines index your website and gain initial visibility.",
        },
        {
          title: "Forms",
          description:
            "Integrated contact form to capture leads and inquiries directly from your website.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Setup of Google Analytics, Microsoft Clarity, and Google Tag Manager, along with monthly performance reports.",
        },
        {
          title: "Support & Changes",
          description:
            "1 year of free support with up to 3 free changes to your website during that period.",
        },
        // {
        //   title: "1-Year Free Delnie Cloud Admin",
        //   description: DelnieCloudAdminDesc,
        // },
      ],
    },
    {
      title: "Advanced Plan",
      price: "₹80,000",
      features: [
        {
          title: "Everything in Basic Plan",
          description:
            "All the features from the Basic Plan, plus advanced options.",
        },
        {
          title: "Pages",
          description: "Up to 25 custom-designed pages.",
        },
        {
          title: "Branding",
          description:
            "Professional logo and branding design for your business.",
        },
        {
          title: "UI/UX Design",
          description:
            "Advanced UI/UX design for a more engaging and seamless user experience.",
        },
        {
          title: "SEO",
          description:
            "Product-level SEO strategies to enhance visibility for your specific product offerings.",
        },
        {
          title: "CMS",
          description:
            "Integration with a content management system (CMS) for easy updates and content management.",
        },
        {
          title: "CRM Integration",
          description:
            "Seamless CRM integration to streamline lead management and customer interactions.",
        },
        {
          title: "Forms",
          description:
            "Advanced contact and inquiry forms with additional functionalities.",
        },
        {
          title: "Support & Changes",
          description:
            "1 year of free support with up to 6 free changes to your website during that period.",
        },
        {
          title: "1-Year Free Delnie Cloud Admin",
          description: DelnieCloudAdminDesc,
        },
      ],
    },
    {
      title: "Premium Plan",
      price: "₹1,50,000",
      features: [
        {
          title: "Everything in Advanced Plan",
          description:
            "All the features from the Advanced Plan, plus premium options.",
        },
        {
          title: "Pages",
          description: "Up to 60 custom-designed pages.",
        },
        {
          title: "Branding",
          description:
            "Premium logo design and a complete branding kit to ensure your business stands out in the industry.",
        },
        {
          title: "UI/UX Design",
          description:
            "Premium custom UI design, giving your brand a unique and distinctive presence.",
        },
        {
          title: "SEO",
          description:
            "Comprehensive SEO strategy with content optimization for maximum visibility.",
        },
        {
          title: "Blogging",
          description:
            "10 custom blog content pieces related to your industry.",
        },
        {
          title: "Automation",
          description:
            "Automation of up to 6 data-related modules in Delnie Cloud Admin to streamline your operations.",
        },
        {
          title: "Support & Changes",
          description:
            "1 year of free support with up to 12 free changes to your website during that period.",
        },
        {
          title: "1-Year Free Delnie Cloud Admin",
          description: DelnieCloudAdminDesc,
        },
      ],
    },
  ];

  // Render plan cards with feature titles and descriptions
  const renderPlan = (plan: Plan) => (
    <div
      className="col-span-12 sm:col-span-6 lg:col-span-4 flex flex-col p-4 sm:p-6" // Adjust padding for mobile
      key={plan.title}
    >
      <Card className="bg-white shadow-2xl rounded-xl flex flex-col h-full">
        <CardContent className="p-6 sm:p-8 flex flex-col justify-between h-full">
          <div>
            <h4 className="text-center font-extrabold text-xl sm:text-2xl text-gray-800 mb-4 sm:mb-6">
              {plan.title}
            </h4>
            <ul className="list-none space-y-4 sm:space-y-6 mb-6 sm:mb-8">
              {plan.features.map((feature, index) => (
                <li key={index}>
                  <p className="font-semibold text-md sm:text-lg text-gray-900">
                    {feature.title}
                  </p>
                  <p className="text-gray-600 mt-1 text-sm sm:text-base">
                    {feature.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          {/* Price and button in a new line */}
          <div className="flex flex-col justify-between mb-4 sm:mb-6">
            <p className="text-2xl sm:text-3xl font-extrabold text-gray-800 whitespace-nowrap text-center">
              {plan.price}
            </p>

            <button
              onClick={props.openEnquiryForm}
              className="w-full py-2 sm:py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg font-semibold shadow-md hover:from-purple-600 hover:to-blue-500 transition mt-4"
            >
              Choose {plan.title.split(" ")[0]}
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <>
      <div className="mt-8 md:mt-12 bg-gradient-to-b from-gray-100 via-white to-gray-200 py-8 sm:py-12">
        {/* {isModalOpen && (
          <EnquiryForm isOpen={isModalOpen} onClose={handleCloseModal} />
        )} */}

        <div className="text-center mb-8 sm:mb-12">
          <p className="text-2xl sm:text-3xl font-bold text-gray-800">
            Our Pricing Plans
          </p>
          <p className="text-sm sm:text-base text-gray-500 mt-2">
            Choose the best plan that suits your business needs
          </p>
        </div>

        <div className="grid grid-cols-12 gap-4 sm:gap-8 px-4 sm:px-6 md:px-12">
          {plans.map((plan) => renderPlan(plan))}
        </div>

        <div className="mt-8 sm:mt-12 text-center px-4 sm:px-6 md:px-12 max-w-screen-md mx-auto">
          <h4 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">
            Still Unsure Which Plan to Choose?
          </h4>
          <p className="text-base sm:text-lg text-gray-600 mb-6">
            Contact us for a free consultation and we’ll guide you to the
            perfect plan for your business.
          </p>
          <button
            // onClick={handleOpenModal}
            onClick={props.openEnquiryForm}

            className="px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full font-semibold shadow-lg hover:from-purple-600 hover:to-blue-500 transition"
          >
            Get Free Consultation &rarr;
          </button>
        </div>
      </div>
    </>
  );
}

export default OurPlans;
