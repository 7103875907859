import React, { useEffect, useState } from "react";

import proofs from "../../../../../assets/images/recent_work/stats_new.svg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { get, post } from "../../../../../utils/api";

import { ServiceNavbar } from "./navbar-section";
import EnquiryForm from "./enquiry-form";
import RecentWorks from "./recent-work";
import DelnieCloudAdminSection from "./cloud-admin";
import StackSuggestion from "./stack-suggestion";
import OurPlans from "./our-palns";
import ClientTestimonials from "./client-testimonials";
import axios from "axios";
import ServiceHeroSection from "./hero-section";
import ServiceFooter from "./footer";
import Snackbar from "../../../ui/snackbar";
import { useNavigate } from "react-router-dom";

function LPWebsiteDesignServices() {
  const navigate = useNavigate(); // Initialize navigate

  const [isEnquiryFormOpen, setEnquiryFormOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isSnackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const [countryCodeData, setCountryCodeData] = useState<any[]>([]);

  useEffect(() => {
    // Fetch country codes when component mounts
    getCountryCodes();
  }, []);

  const getCountryCodes = () => {
    get(`countries`, { limit: 300 })
      .then((data: any) => {
        console.log("countries", data);
        setCountryCodeData(data.data.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Validate phone number (allow only digits and limit to 10)
    if (/^\d{0,10}$/.test(inputValue)) {
      setPhone(inputValue);
      setError(""); // Clear error if the phone number is valid
    } else {
      setError("Please enter a valid 10-digit phone number (numbers only).");
    }
  };

  // Utility Functions
  const handlePhoneCall = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  // const handleWhatsApp = (phoneNumber: string) => {
  //   window.open(
  //     `https://wa.me/${phoneNumber}`,
  //     "_blank",
  //     "noopener,noreferrer"
  //   );
  // };

  const handleWhatsApp = (phoneNumber: string): void => {
    const message = encodeURIComponent(
      "Hi, I hope you're doing well. I would like to discuss a project with you. Please let me know a convenient time for us to connect. Looking forward to your response."
    );
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };
  

  // Function to open the enquiry form
  const openEnquiryForm = () => {
    setEnquiryFormOpen(true);
  };

  // Function to close the enquiry form
  const closeEnquiryForm = () => {
    setEnquiryFormOpen(false);
  };

  // Google Ads conversion function
  const gtag_report_conversion = (url?: string) => {
    const callback = () => {
      if (typeof url !== "undefined") {
        window.location.href = url;
      }
    };
    // Type assertion to let TypeScript know that gtag exists in the window object
    (window as any).gtag("event", "conversion", {
      send_to: "AW-16707017413/brB6CJyysuIZEMW1w54-", // Replace with your conversion ID and label
      value: 1.0,
      currency: "INR",
      event_callback: callback,
    });
    return false;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim().length<5) {
      setError("Please enter a valid name.");
      return;
    }

    if (phone.length<10) {
      setError("Please enter a valid phone number.");
      return;
    }
    if (message.length<10) {
      setError("Please tell us More about your project (Min 10 Char)");
      return;
    }

    setError("");
    const leadData = {
      name,
      phone: countryCode + " " + phone,
      description:message,
    };

    try {
      const res = await post(`delnie_enquiries`, {
        name,
        phone_number: phone,
        description:message,
        country_code: countryCode,
        creation_date: new Date(),
        del: 0,
      });
      closeEnquiryForm();
      gtag_report_conversion();

      // Send to AWS Lambda (Zoho integration)
      const zohoResponse = axios.post(
        "https://19j1hfbxgf.execute-api.ap-south-1.amazonaws.com/dev/delnie_enquiries",
        leadData
      );
      // console.log("Zoho Response:", zohoResponse.data);
      setName("");
      setPhone("");
      setMessage("");
      showSnackbar("Your request has been submitted!");
      // showSnackbar('This is a snackbar message!');
      navigate("/thank-you");

    } catch (error) {
      console.error(error);
      setError("Failed to submit your request. Please try again.");
    }
  };

  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 3000);
  };

  return (
    <>
      <div className="bg-gray-50">
        <ServiceNavbar
          handlePhoneCall={handlePhoneCall}
          handleWhatsApp={handleWhatsApp}
        />
        <ServiceHeroSection
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          message={message}
          setMessage={setMessage}
          error={error}
          countryCode={countryCode}
          handleCountryChange={handleCountryChange}
          countryCodeData={countryCodeData}
          phone={phone}
          handlePhoneChange={handlePhoneChange}
        />{" "}
        <RecentWorks openEnquiryForm={openEnquiryForm} />{" "}
        {/* Pass the form open function */}
        <DelnieCloudAdminSection openEnquiryForm={openEnquiryForm} />
        <ClientTestimonials  />
        <StackSuggestion openEnquiryForm={openEnquiryForm} />
        <OurPlans openEnquiryForm={openEnquiryForm} />
        <ServiceFooter
          handlePhoneCall={handlePhoneCall}
          handleWhatsApp={handleWhatsApp}
        />
        {/* Enquiry Form Modal */}
        <EnquiryForm
          isOpen={isEnquiryFormOpen}
          onClose={closeEnquiryForm}
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          message={message}
          setMessage={setMessage}
          phone={phone}
          handlePhoneChange={handlePhoneChange}
          countryCode={countryCode}
          handleCountryChange={handleCountryChange}
          countryCodeData={countryCodeData}
          error={error}
          // snackbarMessage="Form submitted successfully"
        />
        {isSnackbarOpen && (
          <>
            {/* <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
              {snackbarMessage}
            </div> */}
            <Snackbar
              message={snackbarMessage}
              isOpen={isSnackbarOpen}
              onClose={() => setSnackbarOpen(false)}
            />
          </>
        )}
      </div>
    </>
  );
}

export default LPWebsiteDesignServices;
